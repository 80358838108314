import React from 'react';
import '../components/custom_css/benefit.css';

const Benefits = () => {
  const benefitData = [
    {
      percentage: "40%",
      title: "Cost Reduction",
      description: "Boost efficiency by up to 40%, reduce costs, and enhance decision-making with our AI-powered solutions"
    },
    {
      percentage: "35%",
      title: "Productivity Boost",
      description: "AI-enhanced processes typically increase productivity by an average of 35% annually"
    },
    {
      percentage: "90%",
      title: "Error Reduction",
      description: "Automation can decrease human error rates by up to 90%, making it more efficient and reliable"
    },
    {
      percentage: "5x",
      title: "Decision Speed",
      description: "AI-driven decision making is 5 times faster than conventional methods for most businesses"
    }
  ];

  return (
    <section id="benefit" className="benefits-section bg-gray-100 py-16">
      <div className="container mx-auto px-4">
        <div className="benefits-grid">
          {benefitData.map((benefit, index) => (
            <div key={index} className="benefit-card">
              <h2 className="benefit-percentage">{benefit.percentage}</h2>
              <h3 className="benefit-title">{benefit.title}</h3>
              <p className="benefit-description">{benefit.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Benefits;
