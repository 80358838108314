import React from 'react';
import './custom_css/whatsappButton.css';

const WhatsAppButton = () => {
    const phoneNumber = '601167647525';
    const message = encodeURIComponent('Welcome to Elfa Tech, How can we help you today?');
    const whatsappUrl = `https://wa.me/${phoneNumber}?text=${message}`;

    return (
        <div className="whatsapp-container">
            <span className="whatsapp-tooltip">Contact me through WhatsApp!</span>
            <a 
                href={whatsappUrl} 
                className="whatsapp-button"
                target="_blank"
                rel="noopener noreferrer"
            >
                <i className="fab fa-whatsapp"></i>
            </a>
        </div>
    );
};

export default WhatsAppButton; 