import React, { useState } from 'react';
import '../components/custom_css/legal.css';

const Legal = () => {
  const [activeTab, setActiveTab] = useState('terms');

  return (
    <div className="legal-container">
      <div className="legal-header">
        <h1>Legal Information</h1>
        <div className="legal-tabs">
          <button 
            className={`tab-button ${activeTab === 'terms' ? 'active' : ''}`}
            onClick={() => setActiveTab('terms')}
          >
            Terms of Use
          </button>
          <button 
            className={`tab-button ${activeTab === 'privacy' ? 'active' : ''}`}
            onClick={() => setActiveTab('privacy')}
          >
            Privacy Policy
          </button>
        </div>
      </div>

      <div className="legal-content">
        {activeTab === 'terms' ? (
          <div className="terms-section">
            <h2>Terms of Use</h2>
            <div className="legal-section">
              <h3>1. Acceptance of Terms</h3>
              <p>By accessing and using this website, you accept and agree to be bound by the terms and provision of this agreement.</p>
            </div>

            <div className="legal-section">
              <h3>2. Use License</h3>
              <p>Permission is granted to temporarily download one copy of the materials (information or software) on our website for personal, non-commercial transitory viewing only.</p>
            </div>

            <div className="legal-section">
              <h3>3. Disclaimer</h3>
              <p>The materials on our website are provided on an 'as is' basis. We make no warranties, expressed or implied, and hereby disclaim and negate all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights.</p>
            </div>

            <div className="legal-section">
              <h3>4. Limitations</h3>
              <p>In no event shall our company or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on our website.</p>
            </div>
          </div>
        ) : (
          <div className="privacy-section">
            <h2>Privacy Policy</h2>
            <div className="legal-section">
              <h3>1. Information We Collect</h3>
              <p>We collect information that you provide directly to us, including when you:</p>
              <ul>
                <li>Fill out a form on our website</li>
                <li>Create an account</li>
                <li>Subscribe to our newsletter</li>
                <li>Contact us for support</li>
              </ul>
            </div>

            <div className="legal-section">
              <h3>2. How We Use Your Information</h3>
              <p>We use the information we collect to:</p>
              <ul>
                <li>Provide, maintain, and improve our services</li>
                <li>Send you technical notices and support messages</li>
                <li>Respond to your comments and questions</li>
                <li>Communicate with you about products, services, and events</li>
              </ul>
            </div>

            <div className="legal-section">
              <h3>3. Data Security</h3>
              <p>We implement appropriate technical and organizational measures to protect the security of your personal information.</p>
            </div>

            <div className="legal-section">
              <h3>4. Your Rights</h3>
              <p>You have the right to access, update, or delete your personal information at any time. You can also opt-out of receiving marketing communications from us.</p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Legal;
