import React from "react";
import img from "../images/web.svg";
import img2 from "../images/app.svg";
import img3 from "../images/hosting.svg";
import img4 from "../images/consultation.svg";
import moneyback from "../images/moneyback.png";

const Services = () => {
  return (
    <div id="services" className="bg-gray-100 py-12">
      <section data-aos="zoom-in-down">
        <div className="my-4 py-4">
          <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">
            services
          </h2>

          <div className="flex justify-center">
            <div className="w-24 border-b-4 border-blue-900"></div>
          </div>
          <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">
            We are deeply committed to the growth and success of our clients.
          </h2>
        </div>

        <div className="px-12" data-aos="fade-down" data-aos-delay="600">
          <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-5">
            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={img}
                />
                <h2 className="font-semibold my-4 text-2xl text-center">
                  Web Development
                </h2>
                <p className="text-md font-medium">
                  We create custom, mobile-friendly websites tailored to your
                  business needs, designed to fully integrate AI solutions and
                  maximize their potential for your operations.
                </p>
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={img2}
                />
                <h2 className="font-semibold my-4 text-2xl text-center">
                  Mobile App Development
                </h2>
                <p className="text-md font-medium">
                  We develop high-quality, custom cross-platform mobile apps
                  optimized for performance, scalability, and accessibility. If
                  needed, we build apps that integrate AI automation to enhance
                  your workflows and efficiency.
                </p>
              </div>
            </div>

            <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={img3}
                />
                <h2 className="font-semibold my-4 text-2xl text-center ">
                  AI Automation Solutions
                </h2>
                <p className="text-md font-medium">
                  Our AI Automation Solutions streamline your business
                  processes, enhancing efficiency and minimizing manual tasks.
                  We assess your needs and incorporate AI technology where
                  suitable, delivering tailored automation that empowers your
                  team to focus on growth and innovation.
                </p>
              </div>
            </div>

            {/* <div className="bg-white transition-all ease-in-out duration-400  overflow-hidden text-gray-700 hover:bg-gray-500 hover:text-white rounded-lg shadow-2xl p-3 group">
              <div className="m-2 text-justify text-sm">
                <img
                  alt="card img"
                  className="rounded-t group-hover:scale-[1.15] transition duration-1000 ease-in-out"
                  src={img4}
                />
                <h2 className="font-semibold my-4 text-2xl text-center ">
                  AI Solutions Audit
                </h2>
                <p className="text-md font-medium">
                  Our AI Solutions Audit evaluates your current systems for
                  suitability, compliance with local laws, and best practices.
                  We identify areas for improvement to ensure your AI
                  implementations are effective, ethical, and aligned with
                  regulatory standards.
                </p>
              </div>
            </div> */}
          </div>
        </div>
      </section>

      <section>
        <div className="m-auto max-w-6xl p-2 md:p-12 h-5/6">
          <div
            className="flex flex-col items-center py-8 text-center"
            data-aos="zoom-out"
          >
            <div className="max-w-2xl">
              <div className="flex justify-center mb-2">
                <img
                  src={moneyback}
                  alt="30 Days Money Back Guarantee"
                  className="w-92 h-72 object-contain transform scale-[2]"
                />
              </div>
              <h3 className="text-3xl text-blue-900 font-bold mb-4">
                Customer Satisfaction Guaranteed
              </h3>
              <p className="text-xl text-gray-600 font-semibold">
                We prioritize your satisfaction above all else. That's why we
                offer a 30-day money-back guarantee on all our services. If
                you're not completely satisfied with our solutions, we'll
                provide a full refund - no questions asked.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Services;
