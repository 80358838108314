import React from 'react';
import Clients from '../components/Clients';
import Cta from '../components/Cta';
import Footer from '../components/Footer';
import Hero from '../components/Hero';
import Intro from '../components/Intro';
import Portfolio from '../components/Portfolio';
import Services from '../components/Services';
import LogoSlider from '../components/LogoSlider';
import Benefits from '../components/Benefits';
import FAQ from '../components/faq';
import WhatsAppButton from '../components/WhatsAppButton';

const Home = () => {
    return (
        <>
            <Hero />
            <LogoSlider />
            <Benefits />
            <Intro />
            <Services />
            {/* <Portfolio /> */}
            {/* <Clients /> */}
            <Cta/>
            <FAQ />
            <Footer />
            <WhatsAppButton />
        </>
    )
}

export default Home;

