import React from "react";
import "./custom_css/logoSlider.css";

const logos = [
  { src: require("../images/1.png") },
  { src: require("../images/2.png") },
  { src: require("../images/3.png") },
  { src: require("../images/4.png") },
  { src: require("../images/5.png") },
  { src: require("../images/6.png") },
];

const LogoSlider = () => {
  return (
    <div className="bg-white py-12">
      <div className="my-4 py-4">
        <h2 className="my-2 text-center text-3xl text-blue-900 uppercase font-bold">
          Our Partners
        </h2>
        <div className="flex justify-center">
          <div className="w-24 border-b-4 border-blue-900"></div>
        </div>
        <h2 className="mt-4 mx-12 text-center text-xl lg:text-2xl font-semibold text-blue-900">
          Empowering Businesses with Industry-Leading Solutions
        </h2>
      </div>

      <div className="logo-slider-container">
        <div className="logo-slider-wrapper">
          <div className="logo-slider">
            {[...logos, ...logos].map((logo, index) => (
              <div className="logo-slide" key={index}>
                <img src={logo.src} alt={`Partner Logo ${index + 1}`} />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default LogoSlider;
