import React, { useState } from "react";
import "./custom_css/faq.css";

const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqData = [
    {
      question:
        "What is business automation, and how can it benefit my business?",
      answer:
        "Business workflow automation involves using technology to streamline repetitive and time-consuming tasks, helping businesses operate more efficiently. For example, instead of jumping from one software to another when performing a particular task, an automated solution can handle this without any manual intervention from you. Ultimately, reducing errors and saving time.",
    },
    {
      question:
        "Can you give an example of how business workflow automation works?",
      answer:
        "Certainly! Imagine a manufacturing company automates invoice processing to eliminate manual data entry using OCR to extract invoice details, validate data against supplier records, and automatically route invoices for approval. Once approved, invoices are entered into the accounting system, with payment scheduled based on due dates. This automation reduces errors, saves time for the accounts payable team, and ensures timely payments, ultimately streamlining the entire invoicing workflow.",
    },
    {
      question:
        "What are the key benefits of implementing business automation?",
      answer:
        "By automating workflows, your business can reduce manual effort, minimize human error, and access real-time data insights. This can lead to cost savings, increased productivity, and better decision-making. You can also reallocate your team's time to more strategic work, enhancing both customer satisfaction and employee engagement.",
    },
    {
      question:
        "How customizable are your automation solutions to fit my business's unique workflow?",
      answer:
        "Our solutions are highly customizable. We work closely with each client to understand their specific workflows and business goals, allowing us to create tailored automation systems that meet their exact needs. From reordering supplies to notifying staff of updates, we adapt each solution to fit seamlessly with your existing operations.",
    },
    {
      question:
        "Do I need to have technical knowledge to manage the automated system?",
      answer:
        "No, our systems are designed to be user-friendly and require minimal technical expertise. We provide training and ongoing support, so you and your team can confidently use the system.",
    },
    {
      question: "Will automation disrupt my current business processes?",
      answer:
        "Not at all! We aim to integrate our solutions into your existing workflows smoothly. During the planning phase, we ensure that the automation complements your current processes. We also offer support to help with the transition, ensuring minimal interruption to your daily operations.",
    },
    {
      question:
        "How does your automation service handle data security and privacy?",
      answer:
        "Data security is our top priority. We implement robust security measures to protect your data, following industry best practices and complying with relevant data protectiion regulations. This ensures that your business information and customer data remain safe throughout the automation process.",
    },
    {
      question:
        "Are there any limitations to what your automation solutions can do?",
      answer:
        "While our solutions are highly versatile, there are some limitations. For instance, we can't automate tasks that require human judgment or creativity. However, we can handle routine, repetitive tasks that are well-defined and predictable.",
    },
    {
      question:
        "How does the process work if I decide to work with your agency?",
      answer: (
        <>
          Our process includes several steps to ensure your automation solution
          is tailored to your needs:
          <ul>
            <li>• Discovery Call (free) – to understand your requirements</li>
            <li>• Proposal Development – typically takes one to two weeks</li>
            <li>• Initial Proposal Call – to review the proposed solution</li>
            <li>
              • Proposal Modifications – any adjustments based on your feedback
            </li>
            <li>• Final Proposal Call – for final approval</li>
            <li>• Solution Development – takes about one month</li>
            <li>
              • Solution Delivery & Staff Training – ensuring a smooth
              implementation
            </li>
          </ul>
          This approach ensures your solution is both effective and aligned with
          your specific goals.
        </>
      ),
    },
  ];

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div id="faq" className="faq-container">
      <h1>Frequently Asked Questions</h1>
      <div className="faq-accordion">
        {faqData.map((item, index) => (
          <div className="faq-item" key={index}>
            <button
              className={`faq-question ${
                activeIndex === index ? "active" : ""
              }`}
              onClick={() => toggleAccordion(index)}
            >
              {item.question}
              <span className="faq-icon">
                {activeIndex === index ? "▼" : "▲"}
              </span>
            </button>
            <div
              className={`faq-answer ${activeIndex === index ? "active" : ""}`}
            >
              {item.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
